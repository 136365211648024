<script lang="ts" setup>
// Komponent odpowiedzialny za popup po wylogowaniu z powodu braku aktywności na koncie komornika

import { useBailiffSessionEnded } from '~coreComposables/bailiff-session-ended.composable';

import { EnumPopupSizes } from '~coreTypes/popup.model';

const bailiffSessionEnded = useBailiffSessionEnded();
const { endSessionPopupMessage, endSessionPopupVisibility } = useBailiffSessionEnded();
</script>

<template>
  <CdsPopup
    v-model="endSessionPopupVisibility"
    variant="plain"
    type="default"
    size="regular"
    :width="EnumPopupSizes.SMALL"
    :title="$t('logoutModal.title')"
    :blurred="true"
  >
    {{ endSessionPopupMessage }}
  </CdsPopup>
</template>
